export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon'
  },
  {
    title: 'Database Tables',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Language',
        route: 'table-language',
        icon: 'ListIcon'
      },
      {
        title: 'Currency',
        route: 'table-currency',
        icon: 'ListIcon'
      },
      {
        title: 'Firm',
        route: 'table-firm',
        icon: 'ListIcon'
      },
      {
        title: 'Machine',
        route: 'table-machine',
        icon: 'ListIcon'
      },
      {
        title: 'Machine Group',
        route: 'table-machine-group',
        icon: 'ListIcon'
      },
      {
        title: 'Material Group',
        route: 'table-material-group',
        icon: 'ListIcon'
      },
      {
        title: 'Personnel',
        route: 'table-personnel',
        icon: 'ListIcon'
      },
      {
        title: 'Personnel Group',
        route: 'table-personnel-group',
        icon: 'ListIcon'
      },
      {
        title: 'Problem',
        route: 'table-problem',
        icon: 'ListIcon'
      },
      {
        title: 'Problem Type',
        route: 'table-problem-type',
        icon: 'ListIcon'
      },
      {
        title: 'Site',
        route: 'table-site',
        icon: 'ListIcon'
      },
      {
        title: 'Unit',
        route: 'table-unit',
        icon: 'ListIcon'
      },
      {
        title: 'User',
        route: 'table-user',
        icon: 'ListIcon'
      },
      {
        title: 'Package Detail',
        route: 'table-package-detail',
        icon: 'ListIcon'
      }
    ]
  }
];
